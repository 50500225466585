/**
 * @name: 统计分析-商品销售统计接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 统计分析-商品销售统计接口文件
 * @update: 2023-08-04 17:46
 */
import {get} from "@/request"
import {IPageRes} from "@/apis/page";
import type { IMerchandisingParam, IMerchandishingRes } from "./types";

/**
 * 商品销售统计查询
 * @param data
 * @returns
 */
export const stallStatisticsQueryProductSaleByPageApi = (data: IMerchandisingParam) => get<IMerchandishingRes>("/admin/stallStatistics/queryProductSaleByPage", data)
/**
 * 商品销售导出
 * @param param
 * @returns
 */
export const stallStatisticsProductSaleExportApi = (param: IMerchandisingParam) => get("/admin/stallStatistics/productSaleExport", param, "blob")
