
/**
 * @name: 统计分析-商品销售统计
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 统计分析-商品销售统计
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { stallStatisticsQueryProductSaleByPageApi, stallStatisticsProductSaleExportApi } from "@/apis/statistics/merchandising"
import { gateGetGateListApi } from "@/apis/gate/info"
import { productPriceAttrQueryApi } from "@/apis/product/priceAttr"
import type { IGate } from "@/apis/gate/info/types"
import type { IMerchandishing, IMerchandisingParam } from "@/apis/statistics/merchandising/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IProductPriceAttr } from "@/apis/product/priceAttr/types"
import { deepCopy, exportFile, getPreAndNextTime } from "@/utils/common"

const payWayList = [
  {
    label: "现金",
    value: 1
  },
  {
    label: "微信",
    value: 2
  },
  {
    label: "支付宝",
    value: 3
  },
  {
    label: "混合支付",
    value: 4
  },
  {
    label: "财务结算",
    value: 5
  },
  {
    label: "抖音",
    value: 6
  },
]

@Component({})
export default class statisticsMerchandising extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IMerchandishing[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IMerchandisingParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        align: "center",
        width: 150
      },
      {
        label: "交易流水ID",
        prop: "saleOrderId",
        align: "center",
        width: 180
      },
      {
        label: "销售订单编号",
        prop: "saleOrderSn",
        align: "center",
        width: 180
      },
      {
        label: "交易方式",
        prop: "payWay",
        align: "center",
        width: 100,
        type: "select",
        dicData: payWayList,
      },
      {
        label: "交易方式",
        prop: "payWayList",
        search: true,
        hide: true,
        searchMultiple: true,
        type: "select",
        dicData: payWayList,
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        search: true,
        searchMultiple: true,
        hide: true,
        type: "select",
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({ list: res.data, label: 'gateName', value: 'id' }),
      },
      {
        label: "档口名称",
        prop: "gateName",
        width: 150,
        align: "center"
      },
      {
        label: "分类",
        prop: "productSortName",
        width: 120,
        align: "center"
      },
      {
        label: "销售属性",
        prop: "saleAttrList",
        hide: true,
        search: true,
        searchMultiple: true,
        type: "select",
        dicData: [],
        // dicUrl: "/api/admin/productPriceAttr/query",
        // dicFormatter: (res: any) => ({ list: [{attrName: '零售价'}, ...res.data], label: 'attrName', value: 'attrName' }),
      },
      {
        label: "销售属性",
        prop: "saleAttrName",
        width: 120,
        align: "center"
      },
      {
        label: "销售单价",
        prop: "price",
        slot: true,
        width: 120,
        align: "center"
      },
      {
        label: "销售数量",
        prop: "qty",
        slot: true,
        width: 120,
        align: "center"
      },
      {
        label: "销售金额",
        prop: "productPrice",
        width: 120,
        align: "center"
      },
      {
        label: "交易时间",
        prop: "payTime",
        width: "150",
        align: "center",
        search: true,
        type: "daterange",
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"]
      },
      {
        label: "经办人",
        prop: "handlerName",
        width: 150,
        align: "center"
      }
    ]
  }
  // 销售金额合计
  salePriceTotal = 0

  payWayList = payWayList

  getAttrsList () {
    productPriceAttrQueryApi().then(e => {
      if (e) {
        let arr = e.map(item => {
          return {
            label: item.attrName,
            value: item.attrName
          }
        })
        arr.unshift({
          label: '零售价',
          value: '零售价'
        })
        // @ts-ignore
        this.$refs.crudRef.updateSearchColumn('saleAttrList', {
          dicData: arr
        })
      }
    })
  }

  getList () {
    this.tableLoading = true
    const query: IMerchandisingParam = deepCopy(this.queryParam)
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      query.payTimeStart = this.queryParam.payTime[0]
      query.payTimeEnd = this.queryParam.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime
    if (this.queryParam.gateIdList) {
      // @ts-ignore
      query.gateIdList = this.queryParam.gateIdList.join(',')
    }
    if (this.queryParam.payWayList) {
      // @ts-ignore
      query.payWayList = this.queryParam.payWayList.join(',')
    }
    if (this.queryParam.saleAttrList) {
      // @ts-ignore
      query.saleAttrList = this.queryParam.saleAttrList.join(',')
    }
    stallStatisticsQueryProductSaleByPageApi(query).then(e => {
      this.salePriceTotal = e.productPriceTotal;
      this.tableTotal = e.productSaleVoPage.total
      this.tableData = e.productSaleVoPage.records
      this.tableLoading = false
    })
  }

  reset () {
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.payTime = dateRange;
    this.getList()
  }

  exportExcel () {
    const query: any = deepCopy(this.queryParam)
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      query.payTimeStart = this.queryParam.payTime[0]
      query.payTimeEnd = this.queryParam.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime
    delete query.page
    delete query.limit
    if (query.gateIdList) {
      // @ts-ignore
      query.gateIdList = this.queryParam.gateIdList.join(",")
    }
    if (query.payWayList) {
      // @ts-ignore
      query.payWayList = this.queryParam.payWayList.join(",")
    }
    if (query.saleAttrList) {
      // @ts-ignore
      query.saleAttrList = this.queryParam.saleAttrList.join(",")
    }
    stallStatisticsProductSaleExportApi(query).then(e => {
      exportFile(e, `商品销售统计.xlsx`)
    })
  }

  created () {
    this.getAttrsList()
    this.reset()
  }
}
